import { Countries, Country_code } from '@/core/types';


export type State = {
  countries: Array<Countries>;
  uae_id: number | null;
  countryCode: Array<Country_code>;
  company_info:any;
}

export const state: State = {
  countries: [] as Array<Countries>,
  uae_id:  188,
  countryCode: [] as Array<Country_code>,
  company_info:{
    whatsapp_url:'https://wa.me/971501464046',
    toll_free_number: '800 765 429',
    toll_free_number_tel: 'tel:800765429',
    email:'info@buyanyinsurance.com',
    address:"1406, DAMAC Smart Heights, Barsha Heights Dubai, United Arab Emirates",
    disclaimers: 'Premium Choice Marketing LLC office 1406, Damac Smart Heights, Barsha Heights, Dubai, UAE is the owner of the brand name “ BuyAnyInsurance.com/ae". Insurance is provided by North Star Insurance Services with License No. 227 of the Insurance Authority.'
  }
};