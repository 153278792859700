export enum Links {
    /**
     * @description GET
     */
    GET_COUNTRIES = '/miscellaneous/travel/wiz/countries',
    GET_COUNTRIES_CODE = '/miscellaneous/travel/wiz/country-codes',
    GET_MASTER_CONFIG = '/miscellaneous/travel/master/get-travel-configs',
    GET_RELATIONSHIPS = '/health/relationships',
    GET_VAT = '/miscellaneous/travel/wiz/get-vat',
    GET_LATEST_LEADS_BY_DEVICE_ID = '/miscellaneous/travel/wiz/get-latest-leads-by-device-id',
    GET_LATEST_LEADS_BY_LEAD_ID = '/miscellaneous/travel/wiz/get-latest-leads-by-lead-id',
    GET_LATEST_LEADS_BY_UUID = '/miscellaneous/travel/wiz/get-latest-leads-by-uuid',
    GET_PERSONAL_DETAILS = '/miscellaneous/travel/wiz/get-personal-details',
    GET_QUOTES = '/miscellaneous/travel/wiz/compare-quotes',
    
    /**
     * @description POST
    */
    POST_TRAVELLER_DETAILS = '/miscellaneous/travel/wiz/save-traveller-info',
    POST_PERSONAL_DETAILS = '/miscellaneous/travel/wiz/add-customer-lead',
    PROCEED_BUY = '/miscellaneous/travel/wiz/proceed-buy-now',
    PROCEED_REVIEW = '/miscellaneous/travel/wiz/proceed-review',
    SEND_CHEAP_QUOTES  = '/miscellaneous/travel/wiz/send-cheap-quotes',
    GET_PAYFORT_DATA = '/miscellaneous/travel/wiz/payfort-data',
    POST_CHECKOUT = '/miscellaneous/travel/wiz/temp-checkout',
    SEND_EMAIL = '/miscellaneous/travel/wiz/send-email-by-page',
    POST_COUPON = '/miscellaneous/travel/wiz/coupon/validate',

    GET_COMPANY_INFO = '/api/company-info',

}
