
import { store } from '@/store';
import { defineComponent, computed } from 'vue'

export default defineComponent({
    setup() {
        const websiteUrl = computed(() => {
            return process.env.VUE_APP_PAYMENT_GATEWAY_URL
        })

        const host = computed(() => {
            return (process.env.VUE_APP_FOLDER !== '') ? `/${process.env.VUE_APP_FOLDER}/personal-details` : `/personal-details`;
        });

        const language = computed(() => {
            return window.localStorage.getItem('language');
        })

        const companyInfo = computed(() => {
      return store.state.countries.company_info
    })

        return {
            host,
            language,
            websiteUrl,
            companyInfo
        }
    },
})
